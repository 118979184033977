export const defaultConfig = {

  isOpen: true,

  // // styling
  // borderColor: 'red',
  // borderRadius: '10px',
  // borderStyle: 'solid',

  conversationContainerHeight: '100%', // '500px',
  conversationContainerWidth: '100%', // '300px',

  textColor: 'black',

  inputContainerBackground: 'white',
  conversationContainerBackground: 'white',

  userMessageBackground: 'purple',
  botMessageBackground: 'grey',

  messages: []

};

import React from 'react'
import { useVoteState } from './provider'
import VoteBody from './VoteBody'
import NewVote from './NewVote'
import VoteSettings from './VoteSettings'


export default function VoteRouter({ activeVote, ...props }) {
    const voteState = useVoteState()

    return (
        <>
            {
                voteState.activeVote ? (
                    voteState.settingsOpen ? (
                        <VoteSettings />
                    ) : (
                        <VoteBody />
                    )
                ) : (
                    <NewVote />
                )}
        </>
    )

}

import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react';

import {
    IconButton,
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction,
    Icon,
    Typography,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import AddCircleIcon from '@mui/icons-material/AddCircle';


import cytoscape from 'cytoscape';
import cola from 'cytoscape-cola';
import cxtmenu from 'cytoscape-cxtmenu';
import edgehandles from 'cytoscape-edgehandles';

import { useNoteFunctions, useNoteState } from './provider';

import MDEditor from '@uiw/react-md-editor';
// import MDEditor from '@uiw/react-md-editor/nohighlight';

import "@uiw/react-md-editor/markdown-editor.css";
import "@uiw/react-markdown-preview/markdown.css";


cytoscape.use(cola);
cytoscape.use(cxtmenu);
cytoscape.use(edgehandles);

const code = `
**Hello world!!!**

\`\`\`js
function demo() {}
\`\`\`

\`\`\`python
def demo():
    pass
\`\`\`

# Text functions 

link rendering in markdown: [NASA](www.nasa.gov) 
`

export default function NoteDisplay(props) {

    const noteState = useNoteState()
    const noteFunctions = useNoteFunctions()

    const [noteHeight, setNoteHeight] = React.useState(200)

    useEffect(() => {
        console.log("Loaded Note")

    }, []);

    const [value, setValue] = React.useState(code);

    const containerStyle = {
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid black',
        borderRadius: '5px',
        overflow: 'hidden',
    }

    // I need to calculate the number of pixels high the container is and then set the height of the MDEditor to that value
    // to do this I will need to use a ref to get the height of the container
    const container_ref = useRef(null)
    useEffect(() => {
        if (container_ref.current) {
            console.log(container_ref.current.clientHeight)
            setNoteHeight(container_ref.current.clientHeight)
        }
    }, [container_ref.current, setNoteHeight])
    

    return (
        <>
            <div 
                ref={container_ref}
                style={containerStyle}>
                <MDEditor
                    value={value}
                    onChange={setValue}
                    hideToolbar={true}
                    preview='preview'
                    // height={noteHeight}
                />
            </div>
        </>
    )
}



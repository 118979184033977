import React from 'react'
import { Box, Button, TextField } from '@mui/material'


import { useVoteState, useVoteFunctions } from './provider';




export default function addOption({ ...rest }) {
    const voteState = useVoteState()
    const voteFunctions = useVoteFunctions()

    const [newOption, setNewOption] = React.useState('')

    const contentStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const newOptionValidation = () => {
        if (newOption === '') {
            return false
        }
        // if (voteState.options.filter(option => option.title.toLowerCase() === newOption.toLowerCase()).length > 0) {
        //     return false
        // }
        return true
    }

    const onSubmit = React.useCallback(() => {
        if (newOptionValidation()) {
            console.log("submitting new option", newOption)
            voteFunctions.addOption(newOption)
            setNewOption('')
        }
    }, [newOption, voteFunctions])


    return (
        <>
            <Box style={contentStyle}>
                <TextField
                    label="Add a new option"
                    value={newOption}
                    onChange={(event) => setNewOption(event.target.value)}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            onSubmit()
                        }
                    }}
                />
                <Button
                    variant='contained'
                    style={{ margin: '10px' }}
                    onClick={onSubmit}
                    disabled={!newOptionValidation()}
                >
                    Add Option
                </Button>
            </Box>
        </>
    )
}
export const defaultConfig = {

  isOpen: true,
  settingsOpen: false,

  vote_style_opetions: [
    "Fibbonacci Rank",
    "Approval Voting",
    "Plurality Voting"
  ],
  current_vote_style: "Fibbonacci Rank",

  options: [],
  default_vote_order: [],

  result_visible: true,
  result_options: [
    {
      name: "Mayonayse",
      points: 5
    },
    {
      name: "Ketsup",
      points: 3
    },
    {
      name: "Mustard",
      points: 1
    }
  ],
  result_respondents: [
    {
      name: "John",
      responded: true
    },
    {
      name: "Jane",
      responded: true
    },
    {
      name: "Joe",
      responded: false
    }
  ]


};

import React from 'react';
import { GenericReducer, GenericReducerFunctionMap } from 'contexts/GenericReducer';

import axios from 'axios';
import config from 'config';

// #region Contexts

const contentContext = React.createContext();
function useAuthContext() {
  const context = React.useContext(contentContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
}

const dispatchContext = React.createContext();
function useAuthDispatchContext() {
  const context = React.useContext(dispatchContext);
  if (context === undefined) {
    throw new Error('AuthDispatchContext must be used within an AuthProvider');
  }
  return context;
}
// #endregion

// #region Helper Functions

function getEndpoint() {
  // if we are running in a dev environment, use the config endoint
  if (window.location.hostname.includes('localhost') || window.location.hostname.startsWith('192.168.')) {
    // console.log("Running in development mode, targeting dev endpoint")
    return config.endpoint;
  }

  // If I am on one of the direct pages use the page config.
  const hostendpoint = window.location.host;
  return hostendpoint;
}

function getApiEndpoint() {
  return `https://${getEndpoint()}/api`;
}

export function postAPI(path, body, requestConfig) {
  // Determine proper endpoint
  const endpoint = getApiEndpoint();

  // Build call structure
  if (!requestConfig) {
    requestConfig = {};
  }
  requestConfig.headers = {
    accept: 'application/json',
  };

  return axios.post(endpoint + path, body, requestConfig);
}

function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

function clearSession(context, dispatch) {
  const update_map = {
    session: '',
  };
  GenericReducerFunctionMap(dispatch).UpdateContextStateMap(update_map);
}

function setEmail(context, dispatch, email) {
  const update_map = {
    email: email,
  };
  GenericReducerFunctionMap(dispatch).UpdateContextStateMap(update_map);
}

function updateAuthState(context, dispatch, accessToken, idToken, refreshToken) {
  //  update the context
  GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
    session: '',
    accessToken: accessToken,
    idToken: idToken,
    refreshToken: refreshToken,
    verifying: false,
    authenticated: true,
    idTokenClaims: parseJwt(idToken),
  });
  // update the local storage
  localStorage.setItem('AccessToken', accessToken);
  localStorage.setItem('IdToken', idToken);
  localStorage.setItem('RefreshToken', refreshToken);
}

function doneVerifying(context, dispatch) {
  GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ verifying: false });
}

// #endregion

// #region Auth Functions

function LogAuthSettings(context) {
  console.log('context', context);
}

function GetCachedCredentials(context, dispatch) {
  const accessToken = localStorage.getItem('AccessToken');
  const idToken = localStorage.getItem('IdToken');
  const refreshToken = localStorage.getItem('RefreshToken');
  if (accessToken === null) { return; }
  if (idToken === null) { return; }
  if (refreshToken === null) { return; }
  const idTokenClaims = parseJwt(idToken);
  const update_map = {
    accessToken: accessToken,
    idToken: idToken,
    refreshToken: refreshToken,
    idTokenClaims: idTokenClaims,
  };
  //  update the context
  GenericReducerFunctionMap(dispatch).UpdateContextStateMap(update_map);
  return update_map;
}

function loginRefreshToken(context, dispatch) {
  return new Promise((resolve, reject) => {
    var refreshToken = context.refreshToken;
    if (refreshToken === undefined) {
      const current_credentials = GetCachedCredentials(context, dispatch);
      if (current_credentials === undefined) {
        doneVerifying(context, dispatch);
        reject("No refresh token available");
      }
      refreshToken = current_credentials.refreshToken;
    }
    if (refreshToken === undefined) {
      reject("No refresh token available");
    }
    // then use the refresh token to get a new access token
    postAPI('/public/auth', { refreshtoken: refreshToken })
      .then((response) => {
        if (response.status === 200) {
          const accessToken = response.data.AuthenticationResult.AccessToken;
          const idToken = response.data.AuthenticationResult.IdToken;
          updateAuthState(context, dispatch, accessToken, idToken, refreshToken);
          resolve({ accessToken, idToken });
        } else {
          console.log('Login failed');
        }
      })
      .catch((error) => {
        if (error.code == "ERR_NETWORK") {
          GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ network_error: true });
        }
        else {
          console.log('Login failed for a non-network related reason', error);
        }
        reject(error);
      });
  });
}

function Logout(context, dispatch) {
  clearSession(context, dispatch);
  localStorage.removeItem('AccessToken');
  localStorage.removeItem('IdToken');
  localStorage.removeItem('RefreshToken');
  GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
    accessToken: '',
    idToken: '',
    refreshToken: '',
    idTokenClaims: {},
    authenticated: false,
  });
}

function initiate_auth(context, dispatch, email) {
  /**
   * This function should be called to start the login process
   * It should send an email to the user with a code which can 
   * be verified with the verify_auth function.
   * @param {string} email
   * @returns {Promise}
   * @description
   * This function should be called to start the login process
   * It should send an email to the user with a code which can
   * be verified with the verify_auth function.
   * @example
   * initiate_auth('test@example.com')
   * .then(() => {
   *  console.log("Code sent to email")
   * })
   */
  return new Promise((resolve, reject) => {
    setEmail(context, dispatch, email);
    postAPI('/public/auth', { email })
      .then((response) => {
        console.log('response', response);
        if (response.status === 200) {
          // update the session in the context
          const update_map = {
            session: response.data.Session,
          };
          GenericReducerFunctionMap(dispatch).UpdateContextStateMap(update_map);
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });
}


function verify_auth(context, dispatch, code) {
  /**
   * This function should be called to verify the code sent to the user
   * @param {string} code
   * @returns {Promise} response with the structure {}
   * @description
   */
  return new Promise((resolve, reject) => {
    const session = context.session;
    const email = context.email;
    postAPI('/public/auth', { email, session, code })
      .then((response) => {
        if (response.status === 200) {
          const accessToken = response.data.AuthenticationResult.AccessToken;
          const idToken = response.data.AuthenticationResult.IdToken;
          const refreshToken = response.data.AuthenticationResult.RefreshToken;
          // GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ 
          //   session: '',
          //   accessToken: accessToken,
          //   idToken: idToken,
          //   refreshToken: refreshToken,
          //   verifying: false,
          //   authenticated: true,
          //   idTokenClaims: parseJwt(idToken),
          //  });
          updateAuthState(context, dispatch, accessToken, idToken, refreshToken);
          clearSession(context, dispatch);
          resolve({ success: true });
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });
}

function acquireToken(context, dispatch) {
  return new Promise((resolve, reject) => {
    loginRefreshToken(context, dispatch)
      .then((response) => {
        claims = parseJwt(response.idToken);
        GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ 
          idTokenClaims: claims,
          verifying: false,
          authenticated: true,
         });
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getToken(context, dispatch) {
  return new Promise((resolve, reject) => {
    if (isAuthenticated(context)) {
      resolve(context.accessToken);
    } else {
      acquireToken(context, dispatch).then((response) => {
        resolve(response.accessToken);
      }).catch((e) => {
        // console.log('Get token error', e);
        resolve('');
      });
    }
  });
}

function isAuthenticated(context) {
  if (context.accessToken === undefined) { return false; }
  if (context.accessToken === '') { return false; }
  if (context.idTokenClaims.exp < Date.now() / 1000) { return false; }
  return true;
}

function AuthenticatedCall(context, dispatch, url, method, body) {
  return new Promise((resolve, reject) => {
    getToken(context, dispatch).then((token) => {
      const headers = {
        auth: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      const options = {
        method,
        headers,
      };
      if (body !== undefined) {
        options.body = JSON.stringify(body);
      }
      if (url.startsWith('/')) {
        url = `${getApiEndpoint()}${url}`;
      }
      axios.post(url, body, options)
        .then((response) => {
          try {
            const contentType = response.headers.get('Content-Type');
            if (contentType === null) {
              resolve(response.json());
            } else if (contentType.includes('application/json')) {
              console.log(response)
              try {
                resolve(response.json());
              } catch (error) {
                try {
                  resolve(response.data);
                } catch (error) {
                  console.log('unable to parse response 1', error);
                  console.log(response)
                  reject(error);
                }
              }
              resolve(response.json());
            } else {
              resolve(response.blob());
            }
          } catch (error) {
            console.log('unable to parse response 2', error);
            reject(error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
      .catch((error) => {
        if (error.message === 'Unauthorised') {
          return Login(context);
        }
        reject(error);
      });
  });
}

function getTokenClaims(context, dispatch) {
  return new Promise((resolve, reject) => {
    getToken(context, dispatch).then((token) => {
      const claims = parseJwt(token);
      GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ idTokenClaims: claims });
      resolve(claims);
    });
  });
}


// #endregion

export function useAuthFunctions() {
  const context = useAuthContext();
  const dispatch = useAuthDispatchContext();
  const authFunctions = {
    logSettings: () => LogAuthSettings(context, dispatch),
    logout: () => Logout(context, dispatch),
    isAuthenticated: () => isAuthenticated(context, dispatch),
    authenticatedCall: (url, method, body) => AuthenticatedCall(context, dispatch, url, method, body),
    getToken: () => getToken(context, dispatch),
    GetCachedCredentials: () => GetCachedCredentials(context, dispatch),
    loginRefreshToken: () => loginRefreshToken(context, dispatch),
    initiate_auth: (email) => initiate_auth(context, dispatch, email),
    verify_auth: (code) => verify_auth(context, dispatch, code),
    doneVerifying: () => doneVerifying(context, dispatch),
    getTokenClaims: () => getTokenClaims(context, dispatch),
    getEndpoint: () => getEndpoint(),
  };
  return authFunctions;
}

export function useAuthState() {
  const context = useAuthContext();
  return context;
}

export function AuthProvider({ children, ...rest }) {
  const [configuration, setConfiguration] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);

  const [authContextState, authContextDispatch] = React.useReducer(GenericReducer, {
    ...configuration,
    authenticated: false,
    verifying: true,
    endpoint: getEndpoint(),
    user: {},
    network_error: false,
  });

  React.useEffect(() => {
    loginRefreshToken(authContextState, authContextDispatch)
      .then((result) => {
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
      });
  }, []);

  return loaded ?
    (
      <contentContext.Provider value={authContextState}>
        <dispatchContext.Provider value={authContextDispatch}>
          {children}
        </dispatchContext.Provider>
      </contentContext.Provider>
    ) : (
      <>
        {/* loading screen */}
        <div>Auth Provider Loading</div>
      </>
    );
}

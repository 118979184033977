import React from 'react'
import { ChatProvider } from './provider'
import ChatContainer from './ChatContainer'
import ChatInput from './ChatInput'
import MessageStream from './MessageStream'
import ChatController from './controller'


export default function Chat() {

    return (
        <>
            <ChatProvider>
                <ChatContainer>
                    <ChatController />
                    <MessageStream />
                    <div style={{ height: "20px" }}></div>
                    <ChatInput />
                </ChatContainer>
            </ChatProvider>
        </>
    )

}

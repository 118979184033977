import React from 'react';
import {
  Route, 
  Routes,
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import Main from 'pages/main';
import Splash from 'pages/splash';
import CallTest from 'pages/calltest';
import Chat from 'pages/chat';
import Admin from 'pages/admin';
import Vote from 'pages/vote';
import Flex from '../pages/flex';
import Offline from '../pages/offline';
import { useAuthState } from 'contexts/Auth';

export default function () {
  
  const authState = useAuthState()
  const [useAutedRoutes, setUseAuthedRoutes] = React.useState(authState.authenticated)
  const [offline, setOffline] = React.useState(authState.network_error)
  
  const authenticated_router = (
      <Routes>
        <Route path="/main" element={<Main/>} />
        <Route path="/admin" element={<Admin/>} />
        <Route path="/calltest" element={<CallTest/>} />
        <Route path="/chat" element={<Chat/>} />
        <Route path="/vote/:votename" element={<Vote/>} />
        <Route path="/vote" element={<Vote/>} />
        <Route path="/flex" element={<Flex/>} />
        <Route path="/" element={<Flex/>} /> 
      </Routes>
  )
  const unauthenticated_router = (
      <Routes>
        <Route path="/vote/:votename" element={<Vote/>} />
        <Route path="/vote" element={<Vote/>} />
        <Route path="/" element={<Splash/>} />
      </Routes>
  )

  const offline_router = (
    <Routes>
      <Route path='/' element={<Offline/>} />
    </Routes>
  )

  const RootRouter = React.useCallback(() => {
    if (offline) {
      return offline_router
    }
    return useAutedRoutes ? authenticated_router : unauthenticated_router
  }, [useAutedRoutes])

  const router = createBrowserRouter([
    { path: "*", element: <RootRouter /> },
  ]);

  React.useEffect(() => {
    setUseAuthedRoutes(authState.authenticated)
  }, [authState, authState.authenticated])

  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}


import React from 'react'

import ProfileMenu from '../components/ProfileMenu';
import Chat from '../components/Chat';
import Graph from '../components/Graph';
import JSONView from '../components/JSONView';
import LayoutForm from '../components/Layout/LayoutForm';
import Note from '../components/Note'

import Layout from '../components/Layout';

export default function Flex() {

    const available_components = [
        { name: "Chat", component: <Chat/> },
        { name: "Admin Graph", component: <Graph/> },
        // { name: "Default Graph", component: <Graph/> },
        { name: "profile", component: <ProfileMenu/> },
        { name: "JSONView", component: <JSONView/> },
        { name: "LayoutForm", component: <LayoutForm/> },
        { name: "Note", component: <Note/>},
    ]

    React.useEffect(() => {
        console.log("flexlayout reloaded")
    }, [])


    return (
        <div >
            <Layout
                available_components={available_components}
            />
        </div>
    );


}

import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react';

import {
    IconButton,
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction,
    Icon,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Card,
    CardContent,
    CardActions,
    Button,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNoteFunctions, useNoteState } from './provider';
import { Box } from '@mui/system';




export default function NoteSettings(props) {

    const noteState = useNoteState()
    const noteFunctions = useNoteFunctions()

    const containerDivStyle = {
        // I need it to be able to scroll internally, the parent has overflow hidden
        overflow: 'auto',
        height: '100%',
        width: '100%',
    }




    return (
        <>
            <Box sx={containerDivStyle}>
                <h1>Note Settings</h1>
            </Box>
        </>
    )
}

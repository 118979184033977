import React from 'react';


import { Layout, Model, TabNode } from 'flexlayout-react';
import 'flexlayout-react/style/light.css';


import { useLayoutFunctions, useLayoutState } from './provider';
import { 
    Box,
    Button,
    TextField,
    Typography,
 } from '@mui/material';

 import { DockLocation } from 'flexlayout-react';


 const dumb_new_model = {
    "type": "column",
    "id": "#a73312d0-4df2-488e-9784-891791ed6865",
    "children": [
        {
            "type": "row",
            "id": "#e166c89b-0907-4a45-9b4e-ce3a114973a7",
            "weight": 20,
            "children": [
                {
                    "type": "row",
                    "id": "#18611fc8-1da0-4e6d-ba2b-6fb56bd1883e",
                    "weight": 60,
                    "children": [
                        {
                            "type": "tabset",
                            "id": "#29a1b465-685c-471b-b0d6-428da742e6ac",
                            "weight": 60,
                            "children": [
                                {
                                    "type": "tab",
                                    "id": "#f48f80f8-c95f-43ab-bbff-8d56ed75695c",
                                    "name": "Admin Graph",
                                    "component": "Admin Graph",
                                    "enableRenderOnDemand": false
                                },
                                {
                                    "type": "tab",
                                    "id": "#4594ae25-c743-4a9d-854c-c6884e775faa",
                                    "name": "Default Graph",
                                    "component": "Default Graph"
                                }
                            ]
                        },
                        {
                            "type": "tabset",
                            "id": "#322e2fcb-1766-44eb-90f2-94674e5d7e76",
                            "weight": 60,
                            "children": [
                                {
                                    "type": "tab",
                                    "id": "#87742b3b-c207-4de1-8faf-0aac84e98d39",
                                    "name": "Layout Form",
                                    "component": "LayoutForm"
                                }
                            ],
                            "active": true
                        }
                    ]
                }
            ]
        }
    ]
}

// This is going to be a form to test adding new elements to the layout
export default function LayoutForm(props) {

    const layoutState = useLayoutState()
    const layoutFunctions = useLayoutFunctions()

   const AddNewTabTest = React.useCallback(() => {
        console.log("Adding new tab")
        layoutFunctions.addTab(
            "New Tab", 
            "JSONView",
            "#fcf76896-3761-4d6b-bf0b-bbd5b1196541",
            DockLocation.CENTER,
            0
        )
    }, [layoutFunctions])

    const TestFunction = React.useCallback(() => {
        console.log("layout form test functionality")
        console.log("layoutState", layoutState)
        console.log("layoutFunctions", layoutFunctions)
        console.log("layoutstate.model.doAction", layoutState.model.doAction)
    }, [layoutFunctions, layoutState])

    return (
        <>
            <Box>
                <h1>Layout Form</h1>
                <Button 
                    variant='contained'
                    onClick={AddNewTabTest}>
                    Add Chat
                </Button>
                <Button
                    variant='contained'
                    onClick={TestFunction}>
                        Log state and functions
                    </Button>
            </Box>
           
        </>
    )
}

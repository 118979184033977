
import React from 'react';
import { Box, Card, CardHeader, CardContent, Button, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles';


export default function NewVote() {
    const theme = useTheme()
    const [voteName, setVoteName] = React.useState("")

    const containerCardStyle = {
        width: '75%',
        minWidth: '300px',
        maxWidth: '500px',
        margin: '10px',
        backgroundColor: theme.palette.secondary.main,
    }

    const onSubmit = React.useCallback(() => {
        // navigate to the new vote
        window.location.href = `/vote/${voteName}`
    }, [voteName])

    return (
        <>
            <CardContent align='center'>
                <Box>
                    <TextField
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                onSubmit()
                            }
                        }}
                        onChange={(e) => setVoteName(e.target.value)}
                        value={voteName}
                        label="Vote Name"
                    />
                </Box>
                <Button
                    align='center'
                    variant='contained'
                    onClick={onSubmit}
                    style={{ margin: '10px' }}
                >
                    Start Vote
                </Button>
            </CardContent>
        </>
    )
}
import React from 'react';
import {
    Box,
    Typography,
    TextField,
    Paper,
    Button,
} from '@mui/material'

import { useAuthFunctions, useAuthState } from 'contexts/Auth';

export default function ProfileEdit(props) {
    const auth = useAuthFunctions()
    const authState = useAuthState()
    const [isLoading, setIsLoading] = React.useState(false)
    const handleProcessing = () => {
        setIsLoading(true)
        setInputError('')
        setInputSuccess('')
    }

    const upddateProfileAttributes = React.useCallback((key, value) => {
        console.log("updateProfileAttributes", key, value)
        // auth.updateUser({ [key]: value })
    }, [props])


    React.useEffect(() => {
        console.log("loaded user profile edit")
        auth.logSettings()
        
    }, [])

    const containerStyle = {
        id: 'profileEditContainer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        alignContent: 'center',
    }

    const formStyle = {
        id: 'profileEditForm',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
        margin: '1rem',
    }

    const subFormMenu = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '1rem',
    }

    return (
        <>
            <Box style={containerStyle}>
                <Paper style={formStyle}>
                    <Typography variant="h4" align='center'>Edit Profile</Typography>

                    {/* Lets start deviding this into sections */}
                    
                    <Box style={subFormMenu}>
                        <Typography variant="h6">Public Presentation</Typography>
                    <TextField
                        label="Username"
                        value={authState.user.username}
                        onChange={(e) => { upddateProfileAttributes("username", e.target.value) }}
                    />
                    </Box>

                    <Box style={subFormMenu}>
                        <Typography variant="h6">Contact Information</Typography>
                    <TextField
                        label="Email"
                        placeholder='Email Address'
                        value={authState.user.email}
                        onChange={(e) => { upddateProfileAttributes("email", e.target.value) }}
                    />
                    <TextField
                        label="Phone"
                        value={authState.user.phone}
                        onChange={(e) => { upddateProfileAttributes("phone", e.target.value) }}
                    />
                    </Box>

                    <Box style={subFormMenu}>
                        <Typography variant="h6">Personal Information</Typography>
                    <TextField
                        label="First Name"
                        value={authState.user.first_name}
                        onChange={(e) => { upddateProfileAttributes("first_name", e.target.value) }}
                    />
                    <TextField
                        label="Last Name"
                        value={authState.user.last_name}
                        onChange={(e) => { upddateProfileAttributes("last_name", e.target.value) }}
                    />
                    </Box>

                    <Box style={subFormMenu}>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={auth.updateUser}
                        >
                            Update
                        </Button>
                    </Box>

                </Paper>

            </Box>
        </>
    )
}

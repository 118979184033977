import React from 'react';


import { FlexLayout, Layout, Model, TabNode, Actions, DockLocation } from 'flexlayout-react';
import 'flexlayout-react/style/light.css';

import { useLayoutFunctions, useLayoutState } from './provider';

export default function Display(props) {

    const layoutState = useLayoutState()
    const layoutFunctions = useLayoutFunctions()

    const model = Model.fromJson({
        global: layoutState.global,
        borders: layoutState.borders,
        layout: layoutState.layout,
    });

    const factory = React.useCallback((node) => {
        const component = node.getComponent();
        return layoutFunctions.factory(component);
    }, [layoutFunctions, layoutFunctions.factory, layoutState.factory_array, layoutState.factory_default]);

    const onModelChange = (model) => {
        console.log("model updated")
        // layoutFunctions.updateModel(model)
        console.log("model changed", model);
        console.log("model in json", model.toJson());
    };

    React.useEffect(() => {
        // console.log("flexlayout reloaded")
        layoutFunctions.updateModel(model);
        console.log("layoutState", layoutState )

        // // After 2 seconds add a new tab
        // setTimeout(() => {
        //     var newNode = {
        //         type: "tab",
        //         name: "New Tab",
        //         component: "text",
        //         config: { text: "Content of new tab" }
        //     };
    
        //     // Add the new tab to the existing tabset
        //     const result = model.doAction(Actions.addNode(newNode, model.getRoot().getChildren()[0].getId(), 0));
        //     setModel(model);
        //     console.log("result", result)
        // })

    }, []);



    return (
        <>
            {/* <h1>Display</h1> */}
            <Layout
                model={model}
                factory={factory}
                onModelChange={onModelChange}
            />
        </>
    )
}

import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#811181',
      dark: '#811181', 
      light: '#B7E59A',
    },
    secondary: {
      main: '#8B8B8B', // Medium gray, form elements
    },
    error: {
      main: '#FE1818', // "Alerts and errors"
    },
    // warning: {},
    info: {
      main: '#64C7FF', // "Links and highlights" (brighter blue)
    },
    success: {
      main: '#71BE45', // "Light green highlight"
    },
    background: {
      paper: '#323232',
      default: '#282828',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#A0A0A0',
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ],
  },
});

export default darkTheme;


811181
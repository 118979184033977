import React from "react";
// import 'react-chat-elements/dist/main.css'
import SendIcon from '@mui/icons-material/Send';
import { useChatState, useChatFunctions } from './provider';
import {
    Box,
    TextField,
    IconButton,
} from '@mui/material';


export default function ChatInput({ ...rest }) {
    const [currentInput, setCurrentInput] = React.useState("")
    const chatState = useChatState();
    const chatFunctions = useChatFunctions();
    const inputHasFocus = React.useRef(false)

    const inputContainerStyle = {
        borderBottomLeftRadius: chatState.borderRadius,
        borderBottomRightRadius: chatState.borderRadius,
        width: '100%',
        background: chatState.inputContainerBackground,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        textAlign: 'left',
        flexDirection: 'column'
    }


    const onChange = React.useCallback((e) => {
        setCurrentInput(e.target.value)
    }, [setCurrentInput])

    const onSubmit = React.useCallback(() => {
        console.log("ON SUBMIT CALLED")
        console.log(currentInput)
        setCurrentInput("")
        chatFunctions.PostUserMessage(currentInput)
    }, [currentInput, setCurrentInput])

    const onKeyDown = React.useCallback((e) => {
        if (e.key === 'Enter') {
            if (currentInput === "") {
                return
            }
            onSubmit()
        }
    }, [onSubmit])

    return (
        <>
            <Box style={inputContainerStyle}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                    <TextField
                        id="outlined-basic"
                        label="Type a message"
                        variant="outlined"
                        onChange={onChange}
                        value={currentInput}
                        onKeyDown={onKeyDown}
                        fullWidth
                        autoFocus
                        style={{ margin: "0px 10px 0px 10px" }}
                        
                    />
                    <IconButton
                        onClick={onSubmit}
                        color="primary"
                        align="right"
                    >
                        <SendIcon />
                    </IconButton>
                </div>
            </Box>

        </>
    );
}
import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Favicon from 'media/Favicon';

import Login from 'components/Modals/login';



const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

const logoStyle = {
  width: '200px', // or any size you need
  height: '200px', // or any size you need
};

// style to put the modal in the middle of the screen
const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export default function Splash() {
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  React.useEffect(() => {
    console.log("loaded splash")
  }, [])
  return (
    <Paper style={containerStyle}>
      <Favicon style={logoStyle} />
      <Typography variant="h5" align="center">
        Thank you for your interest!
      </Typography>
      <Typography variant="h5" align="center">
        SocialBoterfly is in closed beta and not currently accepting new users.
      </Typography>
      <Typography variant="h5" align="center">
        Please check back later.
      </Typography>
      <Button 
        onClick={() => setLoginModalOpen(true)}
        variant="contained" 
        color="primary">
            Sign In
        </Button> 
      <Modal
        style={modalStyle}
        open={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}        
      >
        <div>
          <Login />
        </div>
      </Modal>
    </Paper>
  );
}

import React from 'react'
import { LayoutProvider } from './provider'
import LayoutContainer from './container'
import LayoutController from './controller'


export default function Layout({ available_components, ...rest}) {



    return (
        <>
            <LayoutProvider
                available_components={available_components}
                {...rest}
            >
                <LayoutContainer>
                    <LayoutController />
                </LayoutContainer>
            </LayoutProvider>
        </>
    )

}

import React from 'react'
import { CardContent, Button } from '@mui/material'

import AddOption from './AddOption'

import VoteDefault from './VoteDefault';
import VoteResult from './VoteResult';

import { useVoteState, useVoteFunctions } from './provider';

export default function VoteBody() {
    const voteState = useVoteState()
    const voteFunctions = useVoteFunctions()

    const contentStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }

    return (
        <>
                <CardContent style={contentStyle}>
                    <AddOption />

                    {/* There will be more possible options here depending on what vote style is selected. */}
                    <VoteDefault />

                    <Button
                        variant='contained'
                        style={{ margin: '10px' }}
                        onClick={() => {
                            voteFunctions.submitVote()
                        }}
                    >
                        Submit
                    </Button>

                    <VoteResult />


                </CardContent>
        </>
    )
}
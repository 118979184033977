import React from 'react';

export const defaultConfig = {

  isOpen: true,

  model : null,

  global: {
    splitterSize: 2,
    tabEnableClose: true,
    tabEnableDrag: true,
    tabEnableRename: true,
    tabEnableRenderOnDemand: true,
    tabSetEnableMaximize: true,
    tabSetEnableClose: true,
    tabSetEnableDrag: true,
    tabSetEnableDivide: true,
    tabSetEnableMaximize: true,
    tabSetEnableDeleteWhenEmpty: true,
  },

  factory_array: [],
  factory_default: <div>Unknown Component</div>,

  borders: [
		// {
		// 	"type": "border",
		// 	"size": 473.6666660308838,
		// 	"location": "top",
		// 	"children": [
		// 		{
		// 			"type": "tab",
		// 			"id": "#3ca5f70b-68ff-4849-ad36-d6b6523152a7",
		// 			"name": "Output",
		// 			"component": "grid",
		// 			"enableClose": false,
		// 			"icon": "images/bar_chart.svg"
		// 		},
		// 		{
		// 			"type": "tab",
		// 			"id": "#991ce0e5-3f86-4538-be2c-cefc80009899",
		// 			"name": "Layout JSON",
		// 			"component": "json",
    //       "enableClose": false,
		// 		}
		// 	]
		// }
	],

  layout: {
    "type": "column",
    "id": "#a73312d0-4df2-488e-9784-891791ed6865",
    "children": [
        {
            "type": "row",
            "id": "#e166c89b-0907-4a45-9b4e-ce3a114973a7",
            "weight": 20,
            "children": [
                {
                    "type": "row",
                    "id": "#fcf76896-3761-4d6b-bf0b-bbd5b1196541",
                    "weight": 42.91300097751711,
                    "children": [
                        {
                            "type": "tabset",
                            "id": "#1b3445f5-02b9-47c6-bf59-8617ffd03137",
                            "weight": 40,
                            "children": [
                                {
                                    "type": "tab",
                                    "id": "#d78a5a81-78dc-48c9-bfc0-75799b8f6c2b",
                                    "name": "Chat",
                                    "component": "Chat"
                                }
                            ]
                        },
                        {
                            "type": "tabset",
                            "id": "#244ae789-8e24-44a6-862c-d9820a579680",
                            "weight": 60,
                            "children": [
                                {
                                    "type": "tab",
                                    "name": "Note",
                                    "component": "Note"
                                },
                                {
                                    "type": "tab",
                                    "name": "JSONView",
                                    "component": "JSONView"
                                }
                            ]
                        }
                    ]
                },
                {
                    "type": "row",
                    "id": "#18611fc8-1da0-4e6d-ba2b-6fb56bd1883e",
                    "weight": 60,
                    "children": [
                        {
                            "type": "tabset",
                            "id": "#29a1b465-685c-471b-b0d6-428da742e6ac",
                            "weight": 60,
                            "children": [
                                {
                                    "type": "tab",
                                    "id": "#f48f80f8-c95f-43ab-bbff-8d56ed75695c",
                                    "name": "Admin Graph",
                                    "component": "Admin Graph",
                                    "enableRenderOnDemand": false
                                },
                                {
                                    "type": "tab",
                                    "id": "#4594ae25-c743-4a9d-854c-c6884e775faa",
                                    "name": "Default Graph",
                                    "component": "Default Graph"
                                }
                            ]
                        },
                        {
                            "type": "tabset",
                            "id": "#322e2fcb-1766-44eb-90f2-94674e5d7e76",
                            "weight": 60,
                            "children": [
                                {
                                    "type": "tab",
                                    "id": "#87742b3b-c207-4de1-8faf-0aac84e98d39",
                                    "name": "Layout Form",
                                    "component": "LayoutForm"
                                }
                            ],
                            "active": true
                        }
                    ]
                }
            ]
        }
    ]
}

};

import React from 'react';
import { useVoteState, useVoteFunctions } from './provider';
import { Card, CardHeader, IconButton } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuthState } from 'contexts/Auth';

// need tooltip and icon for when they are not logged in to replace the settings icon
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';


import { useTheme } from '@mui/material/styles';


export default function VoteContainer(props) {
  const voteState = useVoteState();
  const voteFunctions = useVoteFunctions();
  const theme = useTheme()
  const authState = useAuthState()

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: '20px',
  }

  const containerCardStyle = {
    width: '75%',
    minWidth: '300px',
    maxWidth: '500px',
    margin: '10px',
    backgroundColor: theme.palette.secondary.main,
  }

  const headerStyle = {
    textAlign: 'center',

  }

  return (
    <>
      {voteState.isOpen && (
        <div style={containerStyle}>
          <Card
            style={containerCardStyle}
            align='center'
          >
            <CardHeader
              title={
                voteState.activeVote ? (
                  `Vote: ${voteState.activeVote}`
                ) : (
                  'Start a New Vote'
                )}
              action={
                <>
                  {
                    voteState.activeVote ? (
                      authState.authenticated ? (
                        <>
                          <IconButton
                            onClick={() => {
                              voteFunctions.toggleSettings()
                            }}
                          >
                            <SettingsIcon />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <Tooltip title="Log in to adjust vote settings.">
                            <InfoIcon />
                          </Tooltip>
                        </>
                      )
                    ) : (
                      <></>
                    )
                  }
                </>
              }
              style={headerStyle}
            />
            {props.children}
          </Card>
        </div >
      )
      }
    </>
  );
}

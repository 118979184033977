import React from 'react';


import 'flexlayout-react/style/light.css';

import { useLayoutFunctions, useLayoutState } from './provider';
import {
    Box,
    Button,
    List,
    ListItem,
    Typography,
} from '@mui/material';


// Currently non-functional selector for picking one of hte available layout types
export default function ComponentSelector(props) {

    const layoutState = useLayoutState()
    const layoutFunctions = useLayoutFunctions()

    // const [available_components, setAvailableComponents] = React.useState(layoutState.factory_array)

    // React.useEffect(() => {
    //     console.log(layoutState.factory_array)
    //     setAvailableComponents(layoutState.factory_array)
    // }, [layoutState, layoutState.factory_array])


    return (
        <>
            <Box>
                <h1>Available Components</h1>
                <p>Is it seeing updates at all?</p>
                {/* {layoutState.factory_array.map((compo, index) => {
                    <p>{console.log(compo)}</p>
                })}
                <List>
                    {available_components.map((component, index) => {
                        <ListItem key={index}>
                            <Typography>{component.name}</Typography>
                            <Typography>Polkadots</Typography>
                        </ListItem>
                    }
                    )}
                </List> */}
            </Box>

        </>
    )
}

import React from 'react';
import { GenericReducer, GenericReducerFunctionMap } from 'contexts/GenericReducer';
import { defaultConfig } from './defaults';

import { useData, useDataFunctions } from 'contexts/Data';


// #region Contexts

const contentContext = React.createContext();
function useContext() {
    const context = React.useContext(contentContext);
    if (context === undefined) {
        throw new Error('useContext must be used within an AuthProvider');
    }
    return context;
}

const dispatchContext = React.createContext();
function useDispatchContext() {
    const context = React.useContext(dispatchContext);
    if (context === undefined) {
        throw new Error('AuthDispatchContext must be used within an AuthProvider');
    }
    return context;
}
// #endregion

// #region Helper Functions

function PostUpdate(context, dispatch, message, source="default") {
    const newMessage = {
        content: message,
        source: source,
        type: "vote_update",
        vote_id: context.activeVote
    }
    context.dataFunctions.postToWebsocket(newMessage)
}

// #endregion

// #region State management functions

function toggleSettings(context, dispatch) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        settingsOpen: !context.settingsOpen
    })
}

// #endregion

// Generic functions

function addOption(context, dispatch, newOption) {
    newOption = {
        title: newOption,
    }
    PostUpdate(context, dispatch, {
        type: "add_option",
        option: newOption
    })
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        options: [...context.options, newOption]
    })
}

function deleteOption(context, dispatch, option) {
    PostUpdate(context, dispatch, {
        type: "remove_option",
        option: option
    })
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        options: context.options.filter((opt) => {
            try {
                return opt.title !== option.title
            } catch (error) {
                return true
            }
        })
    })
}

function submitVote(context, dispatch) {
    const voteStyle = context.current_vote_style
    const voteSelection = context.default_vote_order
    const newMessage = {
        type: "vote_submit",
        vote_type: voteStyle,
        vote_selection: voteSelection
    }
    // console.log("submitVote", newMessage)
    PostUpdate(context, dispatch, newMessage)
}

function resetOptions(context, dispatch) {
    const newMessage = {
        type: "reset_options"
    }
    PostUpdate(context, dispatch, newMessage)
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        options: []
    })
}

// default vote functions

function updateDefaultVoteOptions(context, dispatch, newOrder) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({
        default_vote_order: newOrder
    })
}




// Export Functions

export function useVoteFunctions() {
    const context = useContext();
    const dispatch = useDispatchContext();
    const functionMap = {
        logSettings: () => LogSettings(context, dispatch),
        toggleSettings: () => toggleSettings(context, dispatch),

        PostUpdate: (message) => PostUpdate(context, dispatch, message),


        submitVote: () => submitVote(context, dispatch),
        addOption: (newOption) => addOption(context, dispatch, newOption),
        deleteOption: (option) => deleteOption(context, dispatch, option),
        resetOptions: () => resetOptions(context, dispatch),

        updateDefaultVoteOptions: (newOrder) => updateDefaultVoteOptions(context, dispatch, newOrder),

    };
    return functionMap;
}

export function useVoteState() {
    const context = useContext();
    return context;
}

export function VoteProvider({ children, activeVote, ...rest }) {
    const [loaded, setLoaded] = React.useState(false);
    const data = useData();
    const dataFunctions = useDataFunctions();

    const [contextState, contextDispatch] = React.useReducer(GenericReducer, {
        ...defaultConfig,
        data: data,
        dataFunctions: dataFunctions,
        activeVote: activeVote
        });

    const onVoteUpdateEvent = React.useCallback((data) => {
        console.log("VoteUpdateEvent", data)
        const vote_state = data.vote_state
        const update_map = {}
        // first update the options
        if (vote_state.options) {
            update_map.options = vote_state.options

            var new_ordered_actions = contextState.default_vote_order
            vote_state.options.forEach((option) => {
                if (!new_ordered_actions.includes(option)) {
                    new_ordered_actions.push(option)
                }
            })
            update_map.default_vote_order = contextState.default_vote_order
        }
        GenericReducerFunctionMap(contextDispatch).UpdateContextStateMap(update_map)
    }, [contextState, contextDispatch])

    // when the active vote changes, subscribe to the websocket
    React.useEffect(() => {
        if (contextState.activeVote === "") {
            return
        }
        dataFunctions.subscribeToWebsocketMessage(
            onVoteUpdateEvent,
            {
                "type": "vote_update",
                vote_id: contextState.activeVote
            }
        )
        dataFunctions.postToWebsocket({
            "type": "vote_subscribe",
            "vote_type": "default",
            "vote_id": contextState.activeVote
        })
    }, [contextState.activeVote]);


    React.useEffect(() => {
        console.log("loaded VoteProvider.js")
        setLoaded(true)
    }, [contextState]);

    return loaded ?
        (
            <contentContext.Provider value={contextState}>
                <dispatchContext.Provider value={contextDispatch}>
                    {children}
                </dispatchContext.Provider>
            </contentContext.Provider>
        ) : (
            <>
                <div>Vote Provider Loading</div>
            </>
        );
}




import React from 'react';
import { GenericReducer, GenericReducerFunctionMap } from 'contexts/GenericReducer';

import { useAuthFunctions, useAuthState } from 'contexts/Auth';

// #region Contexts

const contentContext = React.createContext();
function useContext() {
    const context = React.useContext(contentContext);
    if (context === undefined) {
        throw new Error('useContext must be used within an AuthProvider');
    }
    return context;
}

const dispatchContext = React.createContext();
function useDispatchContext() {
    const context = React.useContext(dispatchContext);
    if (context === undefined) {
        throw new Error('AuthDispatchContext must be used within an AuthProvider');
    }
    return context;
}
// #endregion

// #region Helper Functions

function LogSettings(context, dispatch) {
    console.log("LogSettings Data")
    console.log("context", context)
    console.log("dispatch", dispatch)
}

// #endregion

// #region Websocket Functions

function postToWebsocket(context, dispatch, message) {
    if (context.socket.readyState === 1) {
        context.socket.send(JSON.stringify(message))
    }
    else {
        var queuedMessages = context.queuedMessages
        queuedMessages.push(message)
        GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ queuedMessages: queuedMessages })
    }
}

function sendQueuedMessages(context, dispatch) {
    if (context.socket === null) {
        console.log("socket is null, not sending queued messages")
        return
    }
    if (context.socket.readyState !== 1) {
        console.log("socket not ready, not sending queued messages")
        return
    }
    var queuedMessages = context.queuedMessages
    queuedMessages.forEach((message) => {
        context.socket.send(JSON.stringify(message))
    })
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ queuedMessages: [] })
}

function onWebsocketOpen(context, dispatch, event, socket) {
    console.log("socket opened")
    context.auth.getToken().then((token) => {
        const auth_message = {
            type: "auth",
            accessToken: token
        }
        socket.send(JSON.stringify(auth_message))
    })
    
    // rewrite the ping cycle to be purely contained here
    const ping = () => {
        // console.log("socket state", socket)
        if (socket.readyState !== 1) {
            console.log("socket has closed, ending ping cycle")
            return
        }
        setTimeout(ping, 60000);
        socket.send(JSON.stringify({
            type: 'ping',
            action: 'ping',
        }));
    };
    ping();
    context.onWebsocketOpenCallbacks.forEach((callback) => {
        try {
            callback(event)
        } catch (error) {
            console.error("onWebsocketOpen callback error", error)
        }
    })
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ 
        socket: socket,
        websocketConnected: true
    })

}
function subscribeToWebsocketOpen(context, dispatch, callback) {
    var onWebsocketOpenSubscriptions = context.onWebsocketOpenCallbacks
    onWebsocketOpenSubscriptions.push(callback)
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ onWebsocketOpenCallbacks: onWebsocketOpenSubscriptions })
}

function onWebsocketClose(context, dispatch, event) {
    console.log("onWebsocketClose", event)
    context.onWebsocketCloseCallbacks.forEach((callback) => {
        try {
            callback(event)
        } catch (error) {
            console.error("onWebsocketClose callback error", error)
        }
    })
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ websocketConnected: false })
}
function subscribeToWebsocketClose(context, dispatch, callback) {
    var onWebsocketCloseSubscriptions = context.onWebsocketCloseCallbacks
    onWebsocketCloseSubscriptions.push(callback)
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ onWebsocketCloseCallbacks: onWebsocketCloseSubscriptions })
}

function onWebsocketError(context, dispatch, event) {
    console.log("onWebsocketError", event)
    context.onWebsocketErrorCallbacks.forEach((callback) => {
        try {
            callback(event)
        } catch (error) {
            console.error("onWebsocketError callback error", error)
        }
    })
}
function subscribeToWebsocketError(context, dispatch, callback) {
    var onWebsocketErrorSubscriptions = context.onWebsocketErrorCallbacks
    onWebsocketErrorSubscriptions.push(callback)
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ onWebsocketErrorCallbacks: onWebsocketErrorSubscriptions })
}

function onWebsocketMessage(context, dispatch, event) {
    // parse out the data into a json object
    const data = JSON.parse(event.data);
    if (!data) {
        console.error("onWebsocketMessage data is null")
        return
    }
    // ignore ping pong events
    if (data.message === "pong") {
        return
    } else {
        if (data.authenticated) {
            GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ 
                websocketAuthenticated: data.authenticated,
                websocketAuthFlowComplete: true
         })
        }
        context.onWebsocketMessageCallbacks.forEach((subscription) => {
            try {
                // if the subscription has a filter, check if the data matches the filter
                if (subscription.filter) {
                    // for each key in the filter, check if the data has the same key and value
                    var matches = true
                    for (var key in subscription.filter) {
                        if (subscription.filter[key] !== data[key]) {
                            matches = false
                        }
                    }
                    if (matches) {
                        subscription.callback(data)
                    }
                } else {
                    subscription.callback(data)
                }
            } catch (error) {
                console.error("onWebsocketMessage callback error", error)
            }
        })
    }
}
function subscribeToWebsocketMessage(context, dispatch, callback, filter = null) {
    var onWebsocketMessageSubscriptions = context.onWebsocketMessageCallbacks
    onWebsocketMessageSubscriptions.push({
        callback: callback,
        filter: filter
    })
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ onWebsocketMessageCallbacks: onWebsocketMessageSubscriptions })
}

function generateWebsocket(
    context,
    dispatch,
    arg_onWebsocketOpen,
    arg_onWebsocketClose,
    arg_onWebsocketError,
    arg_onWebsocketMessage
) {
    try {
        const socket = new WebSocket(`wss://${context.auth.getEndpoint()}/websocket/`)
        socket.onopen = (event) => { arg_onWebsocketOpen(event, socket) }
        socket.onclose = (event) => { arg_onWebsocketClose(event) }
        socket.onerror = (event) => { arg_onWebsocketError(event) }
        socket.onmessage = (event) => { arg_onWebsocketMessage(event) }
        GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ socket: socket })
    } catch (error) {
        console.log("unable to establish websocket connection")
    }
    
}



// #endregion



// #region Rest Data Functions

function getPrivateServiceHealth(context, dispatch) {
    return new Promise((resolve, reject) => {
        context.auth.authenticatedCall("/health", "private", {})
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                console.error("private call error", err)
                reject(err)
            })
    })
}

// #endregion


export function useDataFunctions() {
    const context = useContext();
    const dispatch = useDispatchContext();
    const functionMap = {
        logSettings: () => LogSettings(context, dispatch),
        getPrivateServiceHealth: () => getPrivateServiceHealth(context, dispatch),
        postToWebsocket: (message) => postToWebsocket(context, dispatch, message),
        subscribeToWebsocketOpen: (callback) => subscribeToWebsocketOpen(context, dispatch, callback),
        subscribeToWebsocketClose: (callback) => subscribeToWebsocketClose(context, dispatch, callback),
        subscribeToWebsocketError: (callback) => subscribeToWebsocketError(context, dispatch, callback),
        subscribeToWebsocketMessage: (callback, filter = null) => subscribeToWebsocketMessage(context, dispatch, callback, filter),
    };
    return functionMap;
}

export function useData() {
    const context = useContext();
    return context;
}

export function DataProvider({ children, ...rest }) {
    const auth = useAuthFunctions()
    const [configuration, ] = React.useState(null);
    const [loaded, setLoaded] = React.useState(false);

    const [contextState, contextDispatch] = React.useReducer(GenericReducer, {
        auth: auth,
        configuration,
        socket: null,
        websocketConnected: false,
        websocketAuthenticated: false,
        websocketAuthFlowComplete: false,
        onWebsocketOpenCallbacks: [],
        onWebsocketCloseCallbacks: [],
        onWebsocketErrorCallbacks: [],
        onWebsocketMessageCallbacks: [],
        queuedMessages: [],
    });

    // The reason for the inner functions is to allow the current version of the state and dispatch to 
    // be used in the callback functions. This is because the contextState and contextDispatch are not
    // updated in the outer functions by default. 
    const innerOnWebsocketOpen = React.useCallback((event, socket) => {
        onWebsocketOpen(contextState, contextDispatch, event, socket)
    }, [contextState, contextDispatch])
    const innerOnWebsocketClose = React.useCallback((event) => {
        onWebsocketClose(contextState, contextDispatch, event)
    }, [contextState, contextDispatch])
    const innerOnWebsocketError = React.useCallback((event) => {
        onWebsocketError(contextState, contextDispatch, event)
    }, [contextState, contextDispatch])
    const innerOnWebsocketMessage = React.useCallback((event) => {
        onWebsocketMessage(contextState, contextDispatch, event)
    }, [contextState, contextDispatch])
    const innerGenerateWebsocket = React.useCallback(() => {
        generateWebsocket(contextState, contextDispatch, innerOnWebsocketOpen, innerOnWebsocketClose, innerOnWebsocketError, innerOnWebsocketMessage)
    }, [contextState, contextDispatch, innerOnWebsocketOpen, innerOnWebsocketClose, innerOnWebsocketError, innerOnWebsocketMessage])

    // Function for when the websocket is opened
    React.useEffect(() => {
        if (contextState.websocketConnected && contextState.websocketAuthenticated) {
            sendQueuedMessages(contextState, contextDispatch)
        }
    }, [contextState.websocketAuthFlowComplete, contextState.websocketConnected])


    React.useEffect(() => {
        innerGenerateWebsocket()
        setLoaded(true)
    }, []);

    return loaded ?
        (
            <contentContext.Provider value={contextState}>
                <dispatchContext.Provider value={contextDispatch}>
                    {children}
                </dispatchContext.Provider>
            </contentContext.Provider>
        ) : (
            <>
                {/* loading screen */}
                <div>Data Provider Loading</div>
            </>
        );
}




import React from 'react'
import { useParams } from 'react-router-dom'
import ProfileMenu from 'components/ProfileMenu';

import Vote from 'components/Vote'


export default function VotePage() {
    const params = useParams()
    // console.log("params", params)
    const activeVote = params.votename

    return (
        <>
             <div>
                <ProfileMenu />
            </div>
            <Vote activeVote={activeVote} />
        </>
    )

}

import React from 'react';

import {
  SpeedDial,
  SpeedDialAction,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useNoteState, useNoteFunctions } from './provider';
import { useDataFunctions } from '../../contexts/Data';
import Template from './display';
import NoteSettings from './settings';

// this is responsible for recieving the websocket events and reacting to them. 
export default function NoteController(props) {
  const noteState = useNoteState();
  const noteFunctions = useNoteFunctions();
  const dataFunctions = useDataFunctions();

  const onNoteUpdate = React.useCallback((data) => {
    console.log("onNoteUpdate", data)

  }, [])

  const onNoteInit = React.useCallback((data) => {
    console.log("onNoteInit", data)
  })



  return (
    <>
      <div hidden={noteState.settingsOpen}>
        <Template />
      </div>
      <div hidden={!noteState.settingsOpen}>
        <NoteSettings />
      </div>
      <SpeedDial
        ariaLabel="SpeedDial example"
        sx={{ position: 'absolute', top: 16, right: 16 }}
        icon={<SettingsIcon />}
        onClick={() => { noteFunctions.toggleSettings() }}
        direction='down'
      >
        <SpeedDialAction
          key="Add Node"
          icon={<AddCircleIcon />}
          tooltipTitle="Add Node"
          onClick={() => { console.log("Add Node not implemented") }}
        />
      </SpeedDial>
    </>
  );
}

import React from 'react';
import { GridTextField } from './Common'


export function emailValid(eml) {
    return /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[A-Za-z]+$/.test(eml)
}

export function EmailField(props) {
    return (
        <GridTextField
            name={props.name || "email"}
            label={props.label || "Email Address"}
            placeholder={props.placeholder || "Email Address *"}
            type={props.type || "email"}
            validationFunction={emailValid}
            {...props}
        />
    )
}

export function AuthenticationCodeField(props) {
    return (
        <GridTextField
            label={"Authentication Code"}
            type={props.type || "tel"}
            variant="outlined"
            {...props}
        />
    )
}



import React from 'react'
import { IconButton, Modal } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import Edit from '@mui/icons-material/Edit';

import { BSpeedDial } from './Core/Menus';
import Login from 'components/Modals/login';
import ProfileEdit from 'components/Modals/profileEdit';
import { useAuthState, useAuthFunctions } from 'contexts/Auth';

export default function ProfileMenu() {
    const authState = useAuthState()
    return (
        <>
            {authState.verifying ? (
                <LoadingMenu />
            ) : (
                <>
                    {authState.authenticated ? (
                        <AuthenticatedMenu />
                    ) : (
                        <UnauthenticatedMenu />
                    )}
                </>
            )}
        </>
    )
}

function LoadingMenu() {
    return (
        <BSpeedDial
            icon={<HourglassBottomIcon fontSize="large" />}
            posname='top-right'
            actions={[]}
            direction={"down"}
        />
    )
}

function AuthenticatedMenu() {
    const auth = useAuthFunctions()
    const [editOpen, setEditOpen] = React.useState(false)


    const logout = () => {
        auth.logout()
    }
    const addActions = [
        { icon: <ExitToAppIcon />, name: 'Logout', onClick: logout },
        { icon: <Edit />, name: 'Edit Profile', onClick: () => { setEditOpen(true) } }
    ]

    const modalStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }

    return (
        <>
            <BSpeedDial
                icon={
                    <PersonIcon fontSize="large" />
                }
                onClick={() => { setEditOpen(true) }}
                posname='top-right'
                actions={[...addActions]}
                direction={"down"}
            />
            <Modal
                open={editOpen}
                onClose={() => { setEditOpen(false) }}
                style={modalStyle}
            >
                <div>
                    <ProfileEdit /> 
                </div>
            </Modal>
        </>
    )
}

function UnauthenticatedMenu() {
    const [loginOpen, setLoginOpen] = React.useState(false)
    const actions = []
    return (
        <>
            <BSpeedDial
                icon={<LoginIcon fontSize="large" />}
                posname='top-right'
                actions={actions}
                direction={"down"}
                onClick={() => { setLoginOpen(true) }}
            />
            <Modal
                open={loginOpen}
                onClose={() => { setLoginOpen(false) }}
            >
                <div>
                    <Login />
                </div>
            </Modal>
        </>
    )
}
import React from 'react';
import { GenericReducer, GenericReducerFunctionMap } from 'contexts/GenericReducer';
import { defaultConfig } from './defaults';

import { useData, useDataFunctions } from 'contexts/Data';


// #region Contexts

const contentContext = React.createContext();
function useContext() {
    const context = React.useContext(contentContext);
    if (context === undefined) {
        throw new Error('useContext must be used within an AuthProvider');
    }
    return context;
}

const dispatchContext = React.createContext();
function useDispatchContext() {
    const context = React.useContext(dispatchContext);
    if (context === undefined) {
        throw new Error('AuthDispatchContext must be used within an AuthProvider');
    }
    return context;
}
// #endregion

// #region Helper Functions

function PostUpdate(context, dispatch, message, source = "default") {
    const newMessage = {
        type: "text",
        content: message,
        source: source,
        type: "note_update",
    }
    // context.dataFunctions.postToWebsocket(newMessage)
}

function openSettings(context, dispatch) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ settingsOpen: true });
}

function closeSettings(context, dispatch) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ settingsOpen: false });
}

function toggleSettings(context, dispatch) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ settingsOpen: !context.settingsOpen})
}

// Export Functions

export function useNoteFunctions() {
    const context = useContext();
    const dispatch = useDispatchContext();
    const functionMap = {
        logSettings: () => LogSettings(context, dispatch),
        PostUpdate: (message) => PostUpdate(context, dispatch, message),

        openSettings: () => openSettings(context, dispatch),
        closeSettings: () => closeSettings(context, dispatch),
        toggleSettings: () => toggleSettings(context, dispatch),
    };
    return functionMap;
}

export function useNoteState() {
    const context = useContext();
    return context;
}

export function NoteProvider({ children, config, ...rest }) {
    const [loaded, setLoaded] = React.useState(false);
    const data = useData();
    const dataFunctions = useDataFunctions();

    const [contextState, contextDispatch] = React.useReducer(GenericReducer, {
        ...defaultConfig,
        ...config,
        data: data,
        dataFunctions: dataFunctions,
    });

    React.useEffect(() => {
        // console.log("loaded NoteProvider.js")
        setLoaded(true)
    }, []);

    return loaded ?
        (
            <contentContext.Provider value={contextState}>
                <dispatchContext.Provider value={contextDispatch}>
                    {children}
                </dispatchContext.Provider>
            </contentContext.Provider>
        ) : (
            <>
                {/* loading screen */}
                <div>Note Provider Loading</div>
            </>
        );
}




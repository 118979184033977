import React from 'react';
import { useLayoutState } from './provider';

export default function LayoutContainer(props) {
  const layoutState = useLayoutState();

  const containerStyle = {

  };


  return (
    <>
      {layoutState.isOpen && (
        <div style={containerStyle}>
          {props.children}
        </div>
      )}
    </>
  );
}

import React from 'react';
import { GenericReducer, GenericReducerFunctionMap } from 'contexts/GenericReducer';
import { defaultConfig } from './defaults';

import { useData, useDataFunctions } from 'contexts/Data';
import ComponentSelector from './componentSelector';

import { Actions } from 'flexlayout-react';


// #region Contexts

const contentContext = React.createContext();
function useContext() {
    const context = React.useContext(contentContext);
    if (context === undefined) {
        throw new Error('useContext must be used within an AuthProvider');
    }
    return context;
}

const dispatchContext = React.createContext();
function useDispatchContext() {
    const context = React.useContext(dispatchContext);
    if (context === undefined) {
        throw new Error('AuthDispatchContext must be used within an AuthProvider');
    }
    return context;
}
// #endregion

// #region Helper Functions

function PostUpdate(context, dispatch, message, source = "default") {
    const newMessage = {
        type: "text",
        content: message,
        source: source,
        type: "layout_update",
    }
    context.dataFunctions.postToWebsocket(newMessage)
}

function updateModel(context, dispatch, model) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ model: model });
}

// wrapper around Actions.addNode(newNodeJson, toNodeId, location, index, select?)
function addTab(context, dispatch, name, component, toNodeId, location, index, select) {
    if (!context.model) {
        console.log("context.model is undefined")
        return;
    }
    console.log("model", context.model)
    const newNodeJson = {
        type: "tab",
        name: name,
        component: component,
    }
    console.log("Actions.ADD_NODE", Actions.ADD_NODE)
    const addNodeAction = Actions.addNode(newNodeJson, toNodeId, location, index, select);
    console.log("addNodeAction", addNodeAction)
    const action_result = context.model.doAction(addNodeAction);
    console.log("action_result", action_result)
}

function factory(context, dispatch, component) {
    // var return_component = context.factory_default
    var return_component = ComponentSelector()
    if (component) {
        context.factory_array.forEach(target => {
            if (target.name == component) {
                return_component = target.component
            }
        });
    }
    return return_component
}


// Export Functions

export function useLayoutFunctions() {
    const context = useContext();
    const dispatch = useDispatchContext();
    const functions = {
        logSettings: () => LogSettings(context, dispatch),
        PostUpdate: (message) => PostUpdate(context, dispatch, message),

        updateModel: (model) => updateModel(context, dispatch, model),
        addTab: (name, component, toNodeId, location, index, select) => addTab(context, dispatch, name, component, toNodeId, location, index, select),
        factory: (component) => factory(context, dispatch, component),
    };
    return functions;
}

export function useLayoutState() {
    const context = useContext();
    return context;
}

export function LayoutProvider({ children, config, available_components, ...rest }) {
    const [loaded, setLoaded] = React.useState(false);
    const data = useData();
    const dataFunctions = useDataFunctions();
    if (!available_components) {
        available_components = []
    }

    const [contextState, contextDispatch] = React.useReducer(GenericReducer, {
        ...defaultConfig,
        ...config,
        data: data,
        dataFunctions: dataFunctions,
        factory_array: available_components,
    });

    React.useEffect(() => {
        setLoaded(true)
    }, []);

    return loaded ?
        (
            <contentContext.Provider value={contextState}>
                <dispatchContext.Provider value={contextDispatch}>
                    {children}
                </dispatchContext.Provider>
            </contentContext.Provider>
        ) : (
            <>
                {/* loading screen */}
                <div>Layout Provider Loading</div>
            </>
        );
}




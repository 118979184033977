import React from 'react'

import { useData, useDataFunctions } from 'contexts/Data';


export default function AdminGraph (props) {
    const data = useData()
    const dataFunctions = useDataFunctions()

    const onAdminGraphUpdateEvent = React.useCallback((data) => {
        console.log("AdminGraphUpdateEvent", data)
    }, [])

    React.useEffect(() => {
        console.log("loaded AdminGraph.js")
        dataFunctions.subscribeToWebsocketMessage(
            onAdminGraphUpdateEvent,
            {"type": "admin_graph_update"}
        )
        
    }, [])
    
    return (
        <>
            <h1>Admin Graph</h1>
        </>
    )
}

























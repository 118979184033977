import React from 'react';
import { useNoteState } from './provider';

export default function NoteContainer(props) {
  const noteState = useNoteState();

  const containerStyle = {
    borderRadius: noteState.borderRadius,
    background: noteState.conversationContainerBackground,
    width: '100%',
    height: '100%',
    borderColor: noteState.borderColor,
    borderStyle: noteState.borderStyle,
    display: 'flex',
    flexDirection: 'column',
    overflow: "hidden"
  };

  return (
    <>
      {noteState.isOpen && (
        <div style={containerStyle}>
          {props.children}
        </div>
      )}
    </>
  );
}

export const defaultConfig = {

  isOpen: true,
  settingsOpen: false,

  graph_type: "admin",

  // // styling
  // borderColor: 'red',
  // borderRadius: '10px',
  // borderStyle: 'solid',

  containerHeight: '700px',
  containerWidth: '700px',

  textColor: 'black',

  startingCytoGraph: [],

  currentBGraph: {
    nodes: [],
    edges: []
  },

  

  nodeTypes: [],
  edgeTypes: [],
  excludedNodeTypes: [
    // "chat:chat_message",
  ],
  excludedEdgeTypes: [
  ],
  edge_types_to_be_parents: [
    "chat:message_association"
  ],



  cytoLayout: {
    name: "cola",
    directed: true,
    animate: true,
    // infinite: true,
    avoidOverlap: true,
    nodeDimensionsIncludeLabels: false,
    // refresh: 1,

    nodeSpacing: 10,
    edgeLengthVal: 45,
  },
  cytoStyle: [
    {
      selector: 'node',
      css: {
        'label': 'data(label)',
        'border-color': 'black',
        'background-fit': 'cover',
        "background-image": function(node) {
          return node.data().backgroundImage ? 
            node.data().backgroundImage : 
            'https://i.imgur.com/xCvzudW.png';
        },
        'text-valign': 'bottom',
        'text-halign': 'center',
        'height': '60px',
        'width': '60px',
        'border-opacity': '1',
        "text-background-opacity": 1,
        "text-background-color": "lightgray"
      }
    },
    {
      selector: 'edge',
      style: {
        'label': 'data(label)' // maps to data.label
      }
    },
    {
      selector: ':selected',
      css: {
        'background-color': 'black',
        'line-color': 'black',
        'target-arrow-color': 'black',
        'source-arrow-color': 'black'
      }
    }
  ]
};

import React from 'react'
import { NoteProvider } from './provider'
import NoteContainer from './container'
import NoteController from './controller'


export default function Note({ ...rest}) {

    var config = {}

    React.useEffect(() => {
        // console.log("loaded graph.js")
    }, [])

    return (
        <>
            <NoteProvider config={config} >
                <NoteContainer>
                    <NoteController />
                </NoteContainer>
            </NoteProvider>
        </>
    )

}

import React from 'react';
import { useLayoutState, useLayoutFunctions } from './provider';
import { useDataFunctions } from '../../contexts/Data';
import Display from './display';

// this is responsible for recieving the websocket events and reacting to them. 
export default function LayoutController(props) {
  const layoutState = useLayoutState();
  const layoutFunctions = useLayoutFunctions();
  const dataFunctions = useDataFunctions();

React.useEffect(() => {
  // console.log("layoutState in controller", layoutState)
}, [layoutState])

return (
  <>
  <h1>Layout Controller</h1>
    <Display />
  </>
);
}

import React from 'react'
import { Box } from '@mui/material'

import { useVoteState, useVoteFunctions } from './provider';

export default function VoteResult() {
    const voteState = useVoteState()
    const voteFunctions = useVoteFunctions()
    const respondants = voteState.result_respondents.filter(respondant => respondant.responded).length
    const totalRespondants = voteState.result_respondents.length
    const waiting_on_list = voteState.result_respondents.filter(respondant => !respondant.responded)



    const contentStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const optionStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    }

    return (
        <>
            {voteState.result_visible && (
                <Box style={contentStyle}>
                    <h1>Results</h1>
                    <h2>Response Rate</h2>
                    <h3>{respondants}/{totalRespondants}</h3>
                    <h2>Waiting On</h2>
                    <h3>{waiting_on_list.map((respondant, index) => (
                        <p key={index}>{respondant.name}</p>
                    ))}</h3>

                    {voteState.result_options.map((option, index) => (
                        <Box style={optionStyle} key={index}>
                            <h4>{option.name}</h4>
                            <h4>{option.points}</h4>
                        </Box>
                    ))}
                </Box>
            )}
        </>
    )
}
import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react';

import cytoscape from 'cytoscape';
// import cola from 'cytoscape-cola';
import cxtmenu from 'cytoscape-cxtmenu';
import edgehandles from 'cytoscape-edgehandles';
import { useGraphFunctions, useGraphState } from './provider';

// cytoscape.use(cola);
cytoscape.use(cxtmenu);
cytoscape.use(edgehandles);

const cyto_container_style = {
    height: "100vh",
    overflow: "hidden",
    textAlign: "left",
    position: "relative",
}

export default function GraphDisplay(props) {

    const graphState = useGraphState()
    const graphFunctions = useGraphFunctions()

    const containerRef = useRef();
    var cy = useRef()
    var edgehandles = useRef()

    const context_menu = {
        menuRadius: function (ele) { return 50; }, // the outer radius (node center to the end of the menu) in pixels. It is added to the rendered size of the node. Can either be a number or function as in the example.
        selector: 'node, edge', // elements matching this Cytoscape.js selector will trigger cxtmenus
        outsideMenuCancel: 10,
        commands: [
            {
                content: 'Delete',
                select: function (ele) {
                    sendRemovalRequest(ele)
                }
            },
            {
                content: 'log',
                select: function (ele) {
                    console.log(ele.data())
                }
            }
        ],
    };
    let edgehandles_defaults = {
        canConnect: function (sourceNode, targetNode) {
            // whether an edge can be created between source and target
            return !sourceNode.same(targetNode); // e.g. disallow loops
        },
        edgeParams: function (sourceNode, targetNode) {
            // for edges between the specified source and target
            // return element object to be passed to cy.add() for edge
            return {};
        },
        hoverDelay: 150, // time spent hovering over a target node before it is considered selected
        snap: true, // when enabled, the edge can be drawn by just moving close to a target node (can be confusing on compound graphs)
        snapThreshold: 10, //50, // the target node must be less than or equal to this many pixels away from the cursor/finger
        snapFrequency: 15, // the number of times per second (Hz) that snap checks done (lower is less expensive)
        noEdgeEventsInDraw: true, // set events:no to edges during draws, prevents mouseouts on compounds
        disableBrowserGestures: true // during an edge drawing gesture, disable browser gestures such as two-finger trackpad swipe and pinch-to-zoom
    };

    const runAnimation = React.useCallback(() => {
        try {
            cy.current.layout(graphState.cytoLayout).run()
        } catch (e) {
            // console.log(e)
        }
    }, [cy])

    const enableEdgeDrawingMode = () => {
        edgehandles.current.enable();
        edgehandles.current.enableDrawMode();
    }
    const disableEdgeDrawingMode = () => {
        edgehandles.current.disable();
        edgehandles.current.disableDrawMode();
    }
    const toggleEdgeDrawingMode = () => {
        if (edgehandles.current.enabled) {
            disableEdgeDrawingMode()
        } else {
            enableEdgeDrawingMode()
        }
    }


    const sendRemovalRequest = React.useCallback((target_element) => {
        try {
            console.log("element_to_remove", target_element)
            let element = target_element.json()

            console.log("the id", element.data.id)
            console.log("element json", element)
            if (element.group === "nodes") {
                // props.removeNode(element.data.id)
                graphFunctions.DeleteElementRequest(element.data.id)
            }
            else if (element.group === "edges") {
                // props.removeEdge(element.data.id)
                graphFunctions.DeleteElementRequest(element.data.id)
            }
        } catch (e) {
            console.log(e)
        }
    }, [props])

    // This is the function that is called when the bGraph is updated in the context
    // this allows the graph to be updated in a reflection of the provider rather than handling its own state.
    React.useEffect(() => {
        var includedNodes = []
        var excludedNodeIds = []
        graphState.currentBGraph.nodes.forEach(node => {
            // console.log("node", node)
            // If the node.type is in graphState.excludedNodeTypes, then skip this node
            if (graphState.excludedNodeTypes.includes(node.type)) {
                excludedNodeIds.push(node.id)
                return
            }
            // find any edges that are supposed to be parents for the node
            var parent = undefined
            graphState.currentBGraph.edges.forEach(edge => {
                if (edge.target === node.id && graphState.edge_types_to_be_parents.includes(edge.type)) {
                    parent = edge.source
                }
            })
            try {
                var target_element = cy.current.$(`#${node.id}`)
                if (target_element.length > 0) {
                    includedNodes.push(node.id)
                    // update the node logic here
                    const cyto_element_data = {
                        data: {
                            id: node.id,
                            label: node.title,
                            image: node.image,
                            backgroundImage: node.backgroundImage,
                            parent: parent
                        }
                    }
                    target_element.data(cyto_element_data)
                } else {
                    includedNodes.push(node.id)
                    try {
                        const cyto_element_data = {
                            data: {
                                id: node.id,
                                label: node.title,
                                image: node.image,
                                backgroundImage: node.backgroundImage,
                                parent: parent
                            }
                        }
                        cy.current.add(cyto_element_data)
                    } catch (e) {
                        console.log(e)
                    }
                }
            } catch (e) {
                console.log("problem with node", node)
                console.log(e)
            }
        })
        graphState.currentBGraph.edges.forEach(edge => {
            if (excludedNodeIds.includes(edge.source) || excludedNodeIds.includes(edge.target)) {
                return
            }
            if (!includedNodes.includes(edge.source) || !includedNodes.includes(edge.target)) {
                return
            }
            if (graphState.excludedNodeTypes.includes(edge.type)) {
                return
            }
            // account for edge types that are parents
            // TODO: This currently doesn't work parents are just not being included properly
            if (graphState.edge_types_to_be_parents.includes(edge.type)) {
                // Instead of making an edge, make the source the parent of the target
                // console.log("make parent", edge)
                try {
                    // cy.current.$(`#${edge.target}`).move({ parent: cy.current.$(`#${edge.source}`) })
                    // lets step by step this
                    var target_element = cy.current.$(`#${edge.target}`)
                    var source_element = cy.current.$(`#${edge.source}`)
                    if (source_element.length > 0 && target_element.length > 0) {
                        // console.log("target_element", target_element)
                        target_element.move({ parent: source_element })
                    }
                } catch (e) {
                    console.log(e)
                }
                return
            }
            // console.log("continuing to add edge", edge)
            var target_element = cy.current.$(`#${edge.id}`)
            if (target_element.length > 0) {
                // update the edge logic here
            } else {
                try {
                    const cyto_element_data = {
                        data: {
                            id: edge.id,
                            source: edge.source,
                            target: edge.target,
                            label: edge.type,
                            image: edge.image
                        }
                    }
                    cy.current.add(cyto_element_data)
                } catch (e) {
                    console.log(e)
                }
            }
        })

        // log the current cy so I can build the query to remove the elements that are no longer in the graph
        if (cy.current) {
            cy.current.elements().forEach(element => {
                // console.log("element", element)
                if (element.group() === "nodes" && !includedNodes.includes(element.id())) {
                    console.log("remove node", element.id())
                    cy.current.remove(element)
                }
            })
        }
        runAnimation()


    }, [cy, graphState, graphState.currentBGraph, graphState.excludedNodeTypes, graphState.edge_types_to_be_parents, graphState.openSettings, runAnimation])

    useEffect(() => {
        const config = {
            container: containerRef.current,
            elements: graphState.startingCytoGraph,
            layout: { ...graphState.cytoLayout },
            animate: true,
            fit: true,
            style: graphState.cytoStyle,
        };
        cy.current = cytoscape(config);
        var menu = cy.current.cxtmenu(context_menu);
        edgehandles.current = cy.current.edgehandles(edgehandles_defaults);
        // console.log("loaded graph menu", menu)
        cy.current.on('drag', 'node', function () {
            runAnimation();
        });
        runAnimation()
        disableEdgeDrawingMode()

    }, []);

    return (
        <>
            <div ref={containerRef}
                style={cyto_container_style}
            />
            <div style={{ position: "absolute", top: "0", right: "0", padding: "10px" }}>
            </div>
        </>
    )
}
